import { useCallback, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import './styles.css';
import { useLocation } from 'react-router-dom';

import VideoPage from '../VideoPage';

const defaultConfig = {
  cccp: {
    title: 'Trustar Capital China Partners 2024 Annual Limited Partners Meeting',
    date: '21 November 2024', 
    time: '09:00 – 12:30 (Hong Kong Time)',
    sessions: [
      {
          "title": "Opening Remarks",
          "speakers": [
              "Yichen ZHANG, Chairman"
          ],
          "time": "0:01:05",
          "from": 65,
          "to": 275,
      },
      {
          "title": "Macro Overview and PE Opportunities",
          "speakers": [
              "Yichen ZHANG, Chairman"
          ],
          "time": "0:04:35",
          "from": 275,
          "to": 3185,
      },
      {
          "title": "Funds Activity Update – CCCP III & IV",
          "speakers": [
              "Boon CHEW, Managing Partner"
          ],
          "time": "0:53:05",
          "from": 3185,
          "to": 4140,
      },
      {
          "title": "CCCP III Portfolio Deep Dive – McDonald's China",
          "speakers": [
              "Derek WANG, Partner"
          ],
          "time": "1:09:00",
          "from": 4140,
          "to": 4620,
      },
      {
          "title": "CCCP III Portfolio Deep Dive – Axilone",
          "speakers": [
              "Hanxi ZHAO, Managing Partner"
          ],
          "time": "1:17:00",
          "from": 4620,
          "to": 5020,
      },
      {
          "title": "CCCP III Portfolio Deep Dive – Jissbon",
          "speakers": [
              "Yan ZHAO, Partner"
          ],
          "time": "1:23:40",
          "from": 5020,
          "to": 5480,
      },
      {
          "title": "CCCP III Portfolio Deep Dive – TrustLink",
          "speakers": [
              "Wesley YE, Managing Director"
          ],
          "time": "1:31:20",
          "from": 5480,
          "to": 5885,
      },
      {
          "break": true,
      },
      {
          "title": "CCCP IV Portfolio Deep Dive – ScentAir",
          "speakers": [
              "Hans ALLEGAERT, Managing Director"
          ],
          "time": "1:38:05",
          "from": 5885,
          "to": 6340,
      },
      {
          "title": "CCCP IV Portfolio Deep Dive – Wanda",
          "speakers": [
              "Wesley YE, Managing Director"
          ],
          "time": "1:45:40",
          "from": 6340,
          "to": 6840,
      },
      {
          "title": "CCCP IV Portfolio Deep Dive – Salion",
          "speakers": [
              "David QIN, Managing Director"
          ],
          "time": "1:54:00",
          "from": 6840,
          "to": 7230,
      },
      {
          "title": "CCCP IV Portfolio Deep Dive – Loscam",
          "speakers": [
              "Derek WANG, Partner"
          ],
          "time": "2:00:30",
          "from": 7230,
          "to": 7620,
      },
      {
          "title": "CCCP IV New Investment – Kaneko",
          "speakers": [
              "Takahide ENDO, Senior Associate"
          ],
          "time": "2:07:00",
          "from": 7620,
          "to": 8190,
      },
      {
          "title": "TCP V New Investment – Guilong",
          "speakers": [
              "Emma WAN, Managing Director"
          ],
          "time": "2:16:30",
          "from": 8190,
          "to": 8680,
      },
      {
          "title": "Keynote Speech",
          "speakers": [
              "Professor Yiping HUANG"
          ],
          "time": "2:24:40",
          "from": 8680,
          "to": 11020,
      },
      {
          "title": "Investment Strategy",
          "speakers": [
              "Eric XIN, Managing Partner"
          ],
          "time": "3:03:40",
          "from": 11020,
          "to": 11470,
      },
      {
          "title": "Digitization",
          "speakers": [
              "Jay CHENG, Head of Trustar Digital"
          ],
          "time": "3:11:10",
          "from": 11470,
          "to": 11760,
      },
      {
          "title": "Capital Markets Update",
          "speakers": [
              "Di ZHANG, Managing Director"
          ],
          "time": "3:16:00",
          "from": 11760,
          "to": 12110,
      },
      {
          "title": "Recap & Closing",
          "speakers": [
              "Yichen ZHANG, Chairman"
          ],
          "time": "3:21:50",
          "from": 12110,
          "to": 12243,
      },
      {
          "title": "Q&A",
          "speakers": [],
          "from": 0
      }
  ],
  },
  tgp: {
    title: 'Trustar Growth Partners 2024 Annual Limited Partners Meeting',
    date: '21 November 2024',
    time: '16:15 – 17:15 (Hong Kong Time)',
    sessions: [
      {
        title: 'Opening Remarks',
        speakers: ['Yichen ZHANG, Chairman'],
      }, {
        title: 'Macro Environment & Investment Strategy', 
        speakers: ['Ray ZHAO, Partner'],
      }, {
        title: 'Fund Financial & Portfolio Overview',
        speakers: ['Ray ZHAO, Partner'],
      }, {
        title: 'Portfolio Case Studies',
        speakers: ['Bo PANG, Director'],
      }, {
        title: 'Case Study – I2Cool',
        speakers: ['Bo PANG, Director'],
      }, {
        title: 'Recap & Closing',
        speakers: ['Ray ZHAO, Partner'],
      }, {
        title: 'Q & A',
        speakers: [],
      },
    ],
  },
  ccjp: {
    title: 'Trustar Capital Japan Partners 2024 Annual Limited Partners Meeting',
    date: '22 November 2024',
    time: '08:30 – 10:00 (Hong Kong Time)',
    sessions: [
      {
        title: 'Welcome Address/Macro Overview',
        speakers: [
          'Hironobu NAKANO, Chairman of Japan Private Equity'
        ],
      }, {
        title: 'Update on Fund Activities, Performance & Fundraising',
        speakers: [
          'Masahiro ITO, Partner, Head of Japan Private Equity'
        ],
      }, {
        title: 'Market & Strategy',
        speakers: [
          'Masahiro ITO, Partner, Head of Japan Private Equity'
        ],
      }, {
        title: 'Team',
        speakers: [
          'Takato FUKUDA, Director'
        ],
      }, {
        title: 'Portfolio Update',
        speakers: [
          'Takato FUKUDA, Director',
          'Xiaoling WANG, Director',
          'Danning XU, Senior Associate',
          'Takahito FURUTA, Senior Associate',
          'Amane HIROTA, Associate'
        ],
      }, {
        title: 'Prospective Investments for Next Fund',
        speakers: [
          'Takahide ENDO, Senior Associate',
          'Taizo SEKO, Vice President',
          'Shinichiro JO, Vice President'
        ],
      }, {
        title: 'Summary/Outlook',
        speakers: [
          'Masahiro ITO, Partner, Head of Japan Private Equity'
        ],
      }, {
        title: 'Recap & Closing',
        speakers: [
          'Yichen ZHANG, Chairman'
        ],
      }, {
        title: 'Q & A',
        speakers: [],
      },
    ],
  },
}

function LoginPage() {
  const [cookies, setCookie, removeCookie] = useCookies(['email']);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loadedLogin, setLoginedLogin] = useState(false);
  const [config, setConfig] = useState(null);
  const { pathname } = useLocation();
  const keyName = pathname.replace('/', '').toLocaleLowerCase();
  const meta = defaultConfig[keyName];

  const sendRequest = async (email) => {
    return await fetch('https://a3ezufr8af.execute-api.ap-east-1.amazonaws.com/login', {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        body: JSON.stringify({ email, type: keyName }),
      })
      .then((response) => response.json())
      .then((response) => {
        if (response.message) {
          throw new Error('Unauthorized');
        }
        setCookie('email', email, { maxAge: 60 * 60 });
        setConfig(response);
        setLoginedLogin(true);
        return true;
      })
      .catch((err) => {
        removeCookie('email');
        setConfig(null);
        setLoginedLogin(true);
        return false;
      });
  }

  useEffect(() => {
    document.title = meta.title

    if (cookies.email) {
      sendRequest(cookies.email);
    } else {
      setLoginedLogin(true);
    }
  }, []);

  const onChange = useCallback((e) => {
    e.preventDefault();
    setEmail(e.target.value);
  }, []);

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const success = await sendRequest(email);
    if (!success) {
      setError('The email address that you\'ve entered doesn\'t match with our record. Please try again.')
    }
  }, [email]);

  return (
    <>
    {loadedLogin && config ? <VideoPage config={config[keyName]} meta={meta} keyName={keyName} /> : null}
    {loadedLogin && !config ?
      <div className="login-wrapper">
        <div>
          <img className="logo" src="./logo.png" />
          <h1>{meta.title}</h1>
          <h4>{meta.date}</h4>
          <h4>{meta.time}</h4>

          <form className="login-form" onSubmit={onSubmit}>
            <input type="email" placeholder="(Email Address)"  value={email} onChange={onChange} />
              <button type="submit" style={{ pointerEvents: 'cursor' }}>
                {'>> Login >>'}
              </button>
            <span className="error">{error}</span>
          </form>
        </div>
      </div> : null}
    </>
  );
}

export default LoginPage;
