// import ReactPlayer from 'react-player';
import { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import './styles.css';
import VideoJS from '../Video'

const throttle = (func, limit) => {
    let inThrottle;
    return function(...args) {
      if (!inThrottle) {
        func.apply(this, args);
        inThrottle = true;
        setTimeout(() => inThrottle = false, limit);
      }
    }
  }

// Add this helper function to convert HH:MM format to seconds
const convertTimeToSeconds = (timeString) => {
    if (!timeString) return 0;
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return (hours * 3600) + (minutes * 60) + seconds;
};

function VideoPage({config, meta, keyName}) {
    const [lang, setLang] = useState('en');
    const playerRef = useRef(null);
    const [activeInfo, setActiveInfo] = useState(null);
    const [timeChanged, setCurrentTime] = useState(0);
    const providedTime = !!meta.sessions.find(session => !!session.from);
    const currentTimeRef = useRef(0);  // Add this ref to store current time

    // Add markers based on session times if they exist
    const markers = meta.sessions
        .filter(session => session.from) // Only include sessions with timestamps
        .map(session => ({
            time: session.from,
            text: session.title
        }));

    const videoJsOptions = useMemo(() => ({
      autoplay: false,
      controls: true,
      responsive: true,
      fluid: true,
      sources: [{
        src: config[lang],
        type: 'video/mp4'
      }],
      markers: {
        markerStyle: {
          'width': '8px',
          'background-color': '#72d5ff',
          'border-radius': '0',
        },
        markerTip: {
          display: true,
          text: function(marker) {
            return marker.text;
          }
        },
        markers: markers
      },
    }), []);

  
  
    const handlePlayerReady = useCallback((player) => {
      playerRef.current = player;
  
      // You can handle player events here, for example:
      player.on('waiting', () => {
        console.log('player is waiting');
      });
  
      player.on('dispose', () => {
        console.log('player will dispose');
      });

    //   player.on('timeupdate', () => {
    //    setCurrentTime(player.currentTime());
    //   });
    }, []);

    const goToTime = (time) => {
      if (playerRef.current) {
        playerRef.current.play();
        playerRef.current.currentTime(convertTimeToSeconds(time));
        }
    }

    // useEffect(() => {
    //   const interval = setInterval(() => {
    //     const timeChanged = currentTimeRef.current;
    //     if (activeInfo) {
    //       const {from, to} = activeInfo;
    //       if (timeChanged >= from && timeChanged <= to) {
    //         return;
    //       }
    //     }
    //     const index = meta.sessions.findIndex(session => timeChanged >= session.from && timeChanged <= session.to);
    //     setActiveInfo({...meta.sessions[index], index});
    //   }, 1000);
    //   return () => clearInterval(interval);
    // }, []);
    // useEffect(() => {
    //     if (activeInfo) {
    //       const {from, to} = activeInfo;
    //       if (timeChanged >= from && timeChanged < to) {
    //         return;
    //       }
    //     }
    //     const index = meta.sessions.findIndex(session => timeChanged >= session.from && timeChanged < session.to);
    //     setActiveInfo({...meta.sessions[index], index});
    // }, [timeChanged]);


    // }, [timeChanged]);

    return (
        <div className="video-wrapper">
            <div className="video">
                <header className="header">
                    <img className="logo" src="./logo.png" alt="logo" />
                    <div className="info">
                        <h3>{meta.title}</h3>
                        <h4>{meta.date}</h4>
                        <h4>{meta.time}</h4>
                    </div>
                </header>
                <div className="videoplayer" style={{width: '100%', backgroundColor: 'black'}}>
                    <div style={{position: 'relative', maxWidth: '100vh', marginLeft: 'auto', marginRight: 'auto' }}>
                        <VideoJS options={videoJsOptions} onReady={handlePlayerReady} src={config[lang]} />
                        {keyName === 'never' && <div className='lang'>
                            <button onClick={() => { setLang('en')}} disabled={lang === 'en'}>English</button>
                            <button onClick={() => { setLang('zh-HK')}}  disabled={lang === 'zh-HK'}>中文</button>
                        </div>}
                    </div>
                </div>
                <div className="filler"></div>
            </div>
            <div className="agenda">
                <h1>Agenda</h1>
                <ul>
                    {meta.sessions.map((session, i) => {

                        if (session.break === true) {
                            return <hr key={`session-${i}`} />;
                        }
                        return (
                            <li key={`session-${i}`} className={`session-toggle ${activeInfo?.index === i ? 'active' : ''}`}>
                                <h4 className="session-title">{session.title}{session.time && <button className="session-time" onClick={() => goToTime(session.time)}>{session.time}</button>}</h4>
                                <div className="session-speakers">
                                    {session.speakers.map((speaker, j) => (
                                        <span key={`speaker-${j}`} className="session-speaker">{speaker}</span>
                                    ))}
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>

        </div>
    );
}

export default VideoPage;
